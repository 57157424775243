<template>
  <div class="container">
    <div class="text-right my-4">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
      <template>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on">
              Add
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Panel</span>
            </v-card-title>
            <v-form
              ref="form"
              @submit="addPanelData"
              method="post"
              v-model="valid"
              lazy-validation
            >
              <v-container class="px-50 v-card-padding" fluid>
                <v-text-field
                  v-model="addPanel.panelName"
                  label="Panel Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
                <div style="color: red">
                  {{ message1 }}
                </div>
              </v-container>
              <v-container class="px-50" fluid> </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate">
                  Submit
                </v-btn>
                <v-btn text @click="reset" class="close_button mx-2"> Close </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>
      <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="error" v-bind="attrs" v-on="on">Delete</v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
          <template>
            <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                  Are you sure you want to delete all the selected Record(s)?
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn text class="confirm_button mx-2" v-on:click="dialog2 = false" @click="deletePanel"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn text @click="dialog2 = false" class="close_button mx-2">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
        <div v-if="deleteItems.length == 0">
          <template>
            <v-card>
              <v-card-text class="center">
                <div class="text-h3 pa-12">
                  Please Select Record(s) to delete
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
      </v-dialog>
    </div>
    <div class="text-right"></div>
    <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox">
              <v-checkbox
                ref="n"
                v-model="allSelected"
                @click="selectAll"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"s
              item-key="product_id"
              :items="panel_data"
              :options.sync="options"
              :server-items-length="total"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:item.selectCheckbox="{ item }">
                <v-checkbox
                  ref="n"
                  v-model="deleteItems"
                  :value="item.panel_id"
                  :key="item.panel_id"
                  @click="addToDelete($event, item.panel_id)"
                ></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-dialog
                  v-model="dialog1"
                  persistent
                  :retain-focus="false"
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon
                      medium
                      color="grey"
                      @click="addToUpdate($event, item.panel_id)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>

                </v-dialog>
              </template>
            </v-data-table>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
     <v-dialog
      v-model="toggleUpdateModal"
      max-width="600"
    >
      <template>
	  <v-card>
      <v-card-title>
        <span class="headline">Update Panel</span>
      </v-card-title>
      <v-form
        ref="form"
        @submit="updatePanelData"
        method="put"
      >
        <v-container class="px-50" fluid>
          <v-text-field
            v-model="updatePanel.panelName"
            label="Panel Name"
            required
            :rules="nameRules"
          ></v-text-field>
          <div style="color: red">
            {{ upmessage1 }}
          </div>
        </v-container>
        <v-container class="px-50" fluid> </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            @click="updatePanelData"
            type="submit"
          >
            Update
          </v-btn>
          <v-btn class="close_button mx-2" text @click="toggleUpdateModal = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
	  </template>
     </v-dialog>
</v-row>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

export default {
  components: { Index, Buttons, Loading },
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      total: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "50px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Panel Name",
          value: "panel_name",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Modified Date",
          value: "modified_date",
          width: "200px",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Action",
          value: "actions",
          width: "150px",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      valid: true,
      checkbox: false,
      panelName: [],
      panel_data: [],
      isLoading: true,
      fullPage: true,
      dialog: false,
      dialog1: false,
      dialog2: false,
      deleteItems: [],
      checked_panelId: [],
      checkcedstr: "",
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      addPanel: {
        panelName: null,
      },
      updatePanel: {
        panelId: null,
        panelName: null,
      },
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      addPanellabel: null,
    };
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    getPanels() {
        console.log("get panels called")
      let check = this;
      const path = check.$url("PANEL_DATA");
      console.log(path)
      check.isLoading = true;
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        .get(path)
        .then((res) => {
          check.isLoading = false;
          console.log(res);
          this.panelName = res.data.payload.panelId;
          this.total = this.panelName.length;
          if (Array.isArray(this.panelName) && this.panelName.length) {
            this.panel_data = [];
            let i;
            for (i = 0; i < this.panelName.length; i++) {
              let tempobj = {
                panel_id: this.panelName[i][0],
                panel_name: this.panelName[i][1],
                modified_date: "",
              };
              this.panel_data.push(tempobj);
              let dateCov;
              dateCov = this.format_date(this.panelName[i][2]);
              this.panel_data[i].modified_date = dateCov;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addPanelData(e) {
      e.preventDefault();
      let check = this;
      console.log(this.addPanel.panelName);
      if (
        this.addPanel.panelName != "" &&
        this.addPanel.panelName != null
      ) {
        this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: check  })
          .post(this.$url("PANEL_DATA"), this.addPanel)
          .then((result) => {
            console.log(result.data.payload);
            this.message1 = result.data.payload[0].panelId;
            this.message2 = result.data.payload[0].panelName;
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].panelId;
              this.dialog = true;
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) added successfully",
                  text: result.data.payload.message,
                });
              }
              this.getPanels();
              this.reset();
            }
            console.log(result);
          });
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      this.toggleUpdateModal = true
      view.panelName.forEach(function (o, i) {
        if (o[0] == atchid) {
          view.updatePanel.panelName = o[1];
          view.updatePanel.panelId = o[0];
        }
      });
    },
    updatePanelData(e) {
      e.preventDefault();
      let data = this;
      if (
        data.updatePanel.panelName != "" &&
        data.updatePanel.panelName != null
      ) {
        this.$fetch({ requiresAuth: true, operation: 'edit', vueScope: data  })
          .put(data.$url("PANEL_DATA"), data.updatePanel)
          .then((result) => {
            this.upmessage1 = result.data.payload.panelId;
            this.upmessage2 = result.data.payload.panelName;
            if (this.upmessage2 == null) {
              this.upmessage1 = result.data.payload.panelId;
            } else {
              this.upmessage1 = null;
              this.dialog1 = false;
              if (result.data.statusCode == 200 && this.upmessage1 == null) {
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) updated successfully",
                  text: result.data.payload.message,
                });
                this.toggleUpdateModal = false
              }
              this.getPanels();
              this.reset();
            }

            console.log(result);
          });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_panelId.indexOf(e.target.value) == -1) {
          this.checked_operationId.push(e.target.value);
        }
      } else {
        this.checked_panelId.splice(
          this.checked_panelId.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_panelId.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deletePanel(e) {
      e.preventDefault();
      let check = this;
      console.log(this.deleteItems);
      this.$fetch({ requiresAuth: true, operation : 'delete', vueScope: check })
        .delete(this.$url("PANEL_DATA"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted",
              text: response.data.payload.message,
            });
          }
          this.getPanels();
          this.deleteItems = [];
        });
    },
    selectAll: function () {
      console.log("y");
      let operation = this.panelName;
      if (this.allSelected == true) {
        if (Array.isArray(operation) && operation.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < operation.length; i++) {
            console.log(operation[i]);
            this.deleteItems.push(operation[i][0]);
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
  },
 watch: {
 '$store.state.Rawdata.current_active_module': function(o) {
 if(o){
 this.getPanels(1);
 }
 },
 },
};
</script>
